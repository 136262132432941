import { Component, Injector, NgZone, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SegmentsDirective } from '@syncfusion/ej2-angular-charts';
import { highlightSearch, SelectEventArgs } from '@syncfusion/ej2-dropdowns';
import { PspUserNotifyIconComponent } from 'src/core-lib/angular/components/navigation/psp-user-notify-icon/psp-user-notify-icon.component';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';
import { UserService } from './core/UserService';
import { StepBarComponent } from './shared/step-bar';
import { IHeaderLink } from 'src/core-lib/ej2/components/navigation/psp-roof-site-header/IPspRoofSiteHeaderModel';

// CMP variables
declare const _satellite: any;
declare const UC_UI: any;
declare const uc: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [provideContext('Header')]
})
export class AppComponent implements OnInit
{
    public roofHeaderLinks: IHeaderLink[] = [];
    
    _segments = [];
    _index;
    activeIndex;
    currentIndex;
    skipAllowedIndex;

    angemeldet = this.langService.getString('UserIconAngemeldet_T');
    abmelden = this.langService.getString('UserIconAbmeldenBtn_T');
    anmelden = this.langService.getString('UserIconAnmeldenBtn_T');
    bearbeiten = this.langService.getString('UserIconBenutzerBearbeiten_T');

    _ufcRouted = false;

    public userNotifyIconHeaderTemplate = '<span class="subheader"></span> <h1></h1> <hr>';

    @ViewChild(PspUserNotifyIconComponent)
    public userNotifyIcon: PspUserNotifyIconComponent;

    public get userNotifyIconHeaderText(): string
    {
        if (!this.userService.isAuthenticated)
            return this.langService.getString('UserIconNichtAngemeldet_T');

        const fullName = `${this.userService.userInfo.GivenName} ${this.userService.userInfo.FamilyName}`;
        return fullName;
    }

    public constructor(
        private router: Router,
        private zone: NgZone,
        public userService: UserService,
        private langService: LangService
    )
    {
        router.events.forEach((event) => {
            if(event instanceof NavigationStart) {
                if(this._segments.length >= 7){
                    this._segments.splice(0, 1);
                }
                switch(event.url){
                    case('/home'):
                        this._segments = [];
                        this._ufcRouted = false;
                        break;
                    case('/project-view'):
                        this._segments = [];
                        this._ufcRouted = false;
                        break;
                    case('/overview'):
                        this._segments = [];
                        this._ufcRouted = false;
                        break;
                    case('/structure-selection'):
                        if (this._segments.length <= 2){
                            this._segments = [{label: 'Musterprojekt', route: '/project-view'}, {label: 'Gebäude auswählen', route: '/structure-selection'}];
                        }
                        this._index = 1;
                        break;
                    case('/demand-indicator'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'}, {label: 'Gebäude auswählen', route: '/structure-selection'}, {label: 'Bedarfskennzahl ermitteln', route: '/demand-indicator'}, {label: 'Auswahl Gleichzeitigkeit', route: '/select-calculation'}];;
                        this._index = 2;
                        break;
                    case('/demand-volumetric-flow'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'}, {label: 'Gebäude auswählen', route: '/structure-selection'}, {label: 'Bedarfsvolumenstrom ermitteln', route: '/demand-volumetric-flow'}];
                        this._index = 2;
                        break;
                    case('/select-calculation'):
                    this._segments = [{label: 'Musterprojekt', route: '/project-view'}, {label: 'Gebäude auswählen', route: '/structure-selection'}, {label: 'Bedarfskennzahl ermitteln', route: '/demand-indicator'}, {label: 'Auswahl Gleichzeitigkeit', route: '/select-calculation'}];
                        this._index = 3;
                        break;
                    case('/circulation-volumetric-flow'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'}, {label: 'Zirkulationsvolumenstrom', route: '/circulation-volumetric-flow'}];
                        this._ufcRouted = true;
                        this._index = 1;
                        break;
                    case('/dte-temperature'):
                        if(this._ufcRouted){
                            this._index = 2;
                        }
                        else{
                            this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung DTE', route: '/dte-temperature'}];
                            this._index = 1;
                        }
                    break;
                    case('/dte'):
                        if(this._ufcRouted){
                            this._index = 2;
                        }
                        else{
                            this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung DTE', route: '/dte'}];
                            this._index = 1;
                        }
                    break;
                    case('/pipeline'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung Rohrleitung', route: '/pipeline'}];
                        this._index = 1;
                    break;
                    case('/select-calculation-ufc'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung UFC', route: '/select-calculation-ufc'}];
                        this._index = 1;
                    break;
                    case('/circulation-flow-ufc'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung UFC', route: '/select-calculation-ufc'}, {label: 'Genaue Ermittlung', route: '/circulation-flow-ufc'}];
                        this._index = 2;
                    break;
                    case('/select-calculation-reservoir'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung Energiespeicher', route: '/select-calculation-reservoir'}];
                        this._index = 1;
                    break;
                    // case('/structure-selection-reservoir'):
                    //     this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung Energiespeicher', route: '/select-calculation-reservoir'},{label: 'Gebäude auswählen', route: '/structure-selection-reservoir'}];
                    //     this._index = 2;
                    // break;
                    case('/residential-power-volume'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung Energiespeicher', route: '/select-calculation-reservoir'},{label: 'Energiespeichervolumen', route: '/residential-power-volume'}];
                        this._index = 2;
                    break;
                    case('/other-power-volume'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung Energiespeicher', route: '/select-calculation-reservoir'},{label: 'Energiespeichervolumen', route: '/other-power-volume'}];
                        this._index = 2;
                    break;
                    case('/hot-water-demand'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung Energiespeicher', route: '/select-calculation-reservoir'},{label: 'Warmwassermenge', route: '/hot-water-demand'}];
                        this._index = 2;
                    break;
                    case('/daily-load-profile'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung Energiespeicher', route: '/select-calculation-reservoir'},{label: 'Warmwassermenge', route: '/hot-water-demand'}, {label: 'Tageslastgang', route: '/daily-load-profile'}];
                        this._index = 3;
                    break;

                    case('/heat-generaing-types'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung Energiespeicher', route: '/select-calculation-reservoir'},{label: 'Warmwassermenge', route: '/hot-water-demand'}, {label: 'Tageslastgang', route: '/daily-load-profile'}, {label: 'Weiter Angaben', route: '/heat-generaing-types'}];
                        this._index = 4;
                    break;
                    case('/result'):
                        this._segments = [{label: 'Musterprojekt', route: '/project-view'},{label: 'Auslegung Energiespeicher', route: '/select-calculation-reservoir'},{label: 'Warmwassermenge', route: '/hot-water-demand'}, {label: 'Tageslastgang', route: '/daily-load-profile'}, {label: 'Weiter Angaben', route: '/heat-generaing-types'}, {label: 'Summenliniendiagramm', route: '/result'}];
                        this._index = 5;
                    break;
                }
            }
          });
    }

    public async ngOnInit(): Promise<void> {
        // Inject CMP scripts
        await this.injectScript("https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js", "usercentrics block bundle");
        uc.deactivateBlocking(['f6nkjdUL', 'dwkRM0PG', 'BJz7qNsdj-7']);

        await this.injectScript("https://assets.adobedtm.com/4bd80a146c61/aa931959472e/launch-1853dc13c3dd.min.js", "adobe launch");
        _satellite.pageBottom();

        // Add consent change event listener
        window.addEventListener("UC_SDK_EVENT", (args: any) => {    
            if(args.detail.event === 'consent_status' && args.detail.action !== 'onInitialPageLoad') {
                window.location.reload();
            }
        });

        this.roofHeaderLinks =
        [
            {
                text: this.langService.getString('ImpressTitle_T'),
                href: this.langService.getString('ImpressTitleHref_URL'),
            },
            {
                text: this.langService.getString('PrivacyPolicy_T'),
                href: this.langService.getString('PrivacyPolicyHref_URL'),
            },
            {
                text: this.langService.getString('Legal_T'),
                href: this.langService.getString('LegalHref_URL'),
            },
            {
                text: this.langService.getString('ViegaInternational_T'),
                href: this.langService.getString('ViegaInternationalHref_URL'),
            },
            {
                text: this.langService.getString('ViegaCookies_T'),
                click: () => UC_UI.showSecondLayer(),
                href: undefined,
            },
        ];
    }

    private async injectScript(src: string, name: string): Promise<void> {
        try{
            // Initialize the script element
            const script: HTMLScriptElement = document.createElement('script');
            script.src = src;

            // Set up the script load promise
            const promise = new Promise<void>((resolve, reject) => {
                script.addEventListener('load', () => { setTimeout(resolve, 10); });
                script.addEventListener('error', error => { reject(error); });
            });

            // Append the script to the DOM head outside the angular zone
            this.zone.runOutsideAngular(() => { document.head.appendChild(script); });

            await promise;

            console.log(`script ${name} injected`);
        } catch(err) {
            console.error(`An error occured while attempting to inject the ${name} script`, err);
        }
    }

    navigateToHomePage(){
        this.router.navigate(['home']);
    }

    sendToHelp(){
        window.open("https://viega.de/av-dteufc-planer-onlinehilfe", "_blank")
    }

    navigateToSegment(event: any){
        this.router.navigate([event.newRoute]);
    }

    public userNotifyIcon_select(event: SelectEventArgs): void
    {
        this.userService.redirectToAccountManagement();
    }

    public userNotifyIcon_buttonClick(): void
    {
        if (this.userService.isAuthenticated)
            this.userService.logout();
        else
            this.userService.login();

        this.userNotifyIcon.closePopup();
    }

    public onClickChangeCookieSettings(): void {
        UC_UI.showSecondLayer()
    }

    public header_logoClick() {
        this.router.navigate(['home']);
    }
}
