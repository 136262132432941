import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileDownloadService } from 'src/core-lib/ej2/services/FileDownloadService';
import { ODataHttpService } from 'src/core-lib/ej2/services/ODataHttpService';
import { IODataQueryResponse } from 'src/core-lib/ej2/utils/IODataQueryResponse';
import { environment } from 'src/environments/environment';
import { UserService } from './UserService';
import { GenerateReportDto } from './BackendApi/generatereportdto.complex';
import { ProjectEntity } from './BackendApi/projectentity.entity';
import { ProjectMetadataEntity } from './BackendApi/projectmetadataentity.entity';
import { LangService } from 'src/core-lib/ej2/services/LangService';
import { GlobalizationService } from 'src/core-lib/ej2/services/GlobalizationService';
import { Subscription } from 'rxjs';

export type DimensioningSchematicType = 'WholeProject' | 'Dte' | 'DteUfc' | 'Ufc';

@Injectable()
export class ProjectService
{
    private invalidFileNameCharsRegex = new RegExp('[^A-Za-z0-9öäüß\-]');
    private _currentProject: ProjectEntity = null;
    private responseData: Blob | string = null;

    public get currentProject(): ProjectEntity
    {
        return this._currentProject;
    }

    constructor(
        private langService: LangService,
        private globalizationService: GlobalizationService,
        private odataHttpService: ODataHttpService,
        private httpClient: HttpClient,
        private userService: UserService,
        private fileDownloadService: FileDownloadService
    ) {}

    /**
     * Loads a project from the server and sets it as current project.
     */
    public async listProjectMetadata(): Promise<ProjectMetadataEntity[]>
    {
        const endpoint = this.projectMetadataCollectionEndpoint();
        const queryResponse: IODataQueryResponse<ProjectMetadataEntity> =
            await this.odataHttpService.query<ProjectMetadataEntity>(endpoint, {}, await this.authorizationHeader());

        return queryResponse.value.map(metadataEntity =>
        {
            this.normalizeBackendProjectData(metadataEntity);
            return metadataEntity;
        });
    }

    /**
     * Loads a project from the server and sets it as current project.
     */
    public async openProject(projectId: string): Promise<ProjectEntity>
    {
        const endpoint = this.singleProjectEndpoint(projectId);
        this._currentProject = await this.odataHttpService.getJson(endpoint, await this.authorizationHeader()) as ProjectEntity;
        this.normalizeBackendProjectData(this._currentProject);

        return this._currentProject;
    }

    /**
     * Writes the current project to the backend.
     */
    public async saveProject(): Promise<ProjectEntity>
    {
        if (!this.currentProject)
            throw new Error('There is currently no project loaded.');

        const endpoint = this.singleProjectEndpoint(this.currentProject.ProjectId);
        // the backend will return the saved project with some additional assigned properties such as last modified date etc.
        this._currentProject = await this.odataHttpService.putJson(endpoint, JSON.stringify(this.currentProject), await this.authorizationHeader()) as ProjectEntity;
        return this._currentProject;
    }

    public async saveTempProject(project: ProjectEntity): Promise<ProjectEntity>
    {
        if (!project)
            throw new Error('No project to save provided');

        const endpoint = this.singleProjectEndpoint(this.currentProject.ProjectId);
        this._currentProject = await this.odataHttpService.putJson(endpoint, JSON.stringify(project), await this.authorizationHeader()) as ProjectEntity;
        return this._currentProject;
    }

    /**
     * Adds a new project and sets it as the current project.
     */
    public async addNewProject(project: ProjectEntity): Promise<ProjectEntity>
    {
        const endpoint = this.projectCollectionEndpoint();

        // the backend will return the saved project with some additional assigned properties such as projectId, creation date etc.
        this._currentProject = await this.odataHttpService.postJson(endpoint, JSON.stringify(project), await this.authorizationHeader()) as ProjectEntity;
        this.normalizeBackendProjectData(this._currentProject);

        return this._currentProject;
    }

    /**
     * Delets an existing project.
     */
    public async deleteProject(projectId: string): Promise<void>
    {
        const endpoint = this.singleProjectEndpoint(projectId);
        await this.odataHttpService.deleteJson(endpoint, projectId, await this.authorizationHeader());
    }

    /**
     * Gets an xml+svg schematic for the current project from the backend.
     */
    public async getSchematic(schematicType: DimensioningSchematicType, locale: string): Promise<string>
    {
        if (!this.currentProject)
            throw new Error('There is currently no project loaded.'); //TODO wenn Datenmodell fertig

        const endpoint = `${this.singleProjectEndpoint(this.currentProject.ProjectId)}/GetSchematic(SchematicType='${encodeURIComponent(schematicType)}', Locale='${encodeURIComponent(locale)}')`; //TODO wenn Datenmodell fertig
        const headers = { 'Accept': 'image/svg+xml' };
        const authorizationHeader: string | null = await this.authorizationHeader();
        if (authorizationHeader !== null)
            headers['Authorization'] = authorizationHeader;

        return await this.httpClient.get(endpoint, { headers, responseType: 'text' }).toPromise();
    }

    /**
     * Generates a zip report for the current project using the backend.
     */
    public async generateReport(
        locale: string,
        attachmentsToInclude: string[],
        includeSpecificationTexts: boolean,
        containsAllAttachments: boolean,
        cancellation: { isCancellationRequested: boolean }
    ): Promise<[boolean, Blob | string]>
    {
        if (!this.currentProject)
            throw new Error('There is currently no project loaded.');

        const projectNameSanitized = (this.currentProject.ProjectName || 'project').replace(this.invalidFileNameCharsRegex, '_');
        const projectDateTimeString = this.globalizationService.dateToHumanReadableString(this.currentProject.LastUpdatedUtc, 'dateTime')
            .replace(/\./g, '-').replace(/, /g, '_').replace(/:/g, '_');
        const contentDescriptionString = this.langService.getString('ZipReport.' + (containsAllAttachments ? 'FullZipFileName_Substr' : 'PartZipFileName_Substr'));

        const pdfFileName = this.langService.getString('ZipReport.PdfFileNameFormat_T', undefined, projectNameSanitized, projectDateTimeString);
        const zipFileName = this.langService.getString('ZipReport.ZipFileNameFormat_T', undefined, projectNameSanitized, projectDateTimeString, contentDescriptionString);
        const body: GenerateReportDto =
        {
            Locale: locale,
            AttachmentsToInclude: attachmentsToInclude,
            IncludeSpecificationTexts: includeSpecificationTexts,
            PdfFileName: pdfFileName,
        };

        const endpoint = `${this.singleProjectEndpoint(this.currentProject.ProjectId)}/GenerateReport`;
        const headers = { 'Accept': 'application/*' };
        const authorizationHeader: string | null = await this.authorizationHeader();
        if (authorizationHeader !== null)
            headers['Authorization'] = authorizationHeader;

        // Post generation request and keep the overvable
        let state: 'Running' | 'Done' | 'Error' = 'Running';
        let error: any;
        let requestObservable: Subscription;
        try{
            console.log("Sending generation request");
            requestObservable = this.httpClient.post(endpoint, body, { headers, responseType: 'blob' })
                .subscribe(responseData => {
                    console.log(`Received request response file type: ${responseData?.type}`)
                    let downloadFileName: string;

                    if (responseData?.type === 'application/pdf')
                        downloadFileName = pdfFileName;
                    else if (responseData?.type === 'application/zip')
                        downloadFileName = zipFileName;
                    else {
                        throw new Error('Backend returned unexpected file type.');
                    }

                    this.responseData = responseData;
                    this.fileDownloadService.issueDownload(responseData, downloadFileName);
                    state = 'Done';
                });
        }
        catch(e){
            error = e;
            state = 'Error';
            console.error(e);
        }
        
        if(!requestObservable) {
            console.error("requestObservable is null");
            return new Promise((_, reject) => reject(new Error("Could not send POST request")));
        }

        // Return a promise checking if the request has finished or should be canceled
        return new Promise<[boolean, Blob | string]>((resolve, reject) => {
            const interval = setInterval(() => { // Check the request state in setInterval() to avoid blocking the thread with a while loop
                if(state == 'Done') {
                    console.log(`Resolving generation proimse: ${state}`);
                    clearInterval(interval);
                    resolve([true, this.responseData]);
                } else if(cancellation.isCancellationRequested == true) {
                    console.log("Request canceled by user");
                    requestObservable.unsubscribe(); // Unsubscribing will cause the request to cancel and trigger the backend's CancellationToken
                    clearInterval(interval);
                    resolve([false, null]);
                } else if(state == null || state == 'Error' || requestObservable == null || (requestObservable.closed && state == 'Running')) {
                    console.log("Rejecting generation proimse");
                    reject(error != null ? error : new Error("Encountered an unhandled error during generation"));
                }
            }, 250);
        });
    }

    getDownloadFileName(fileType: string, containsAllAttachments: boolean):string{
        const projectNameSanitized = (this.currentProject.ProjectName || 'project').replace(this.invalidFileNameCharsRegex, '_');
        const projectDateTimeString = this.globalizationService.dateToHumanReadableString(this.currentProject.LastUpdatedUtc, 'dateTime')
            .replace(/\./g, '-').replace(/, /g, '_').replace(/:/g, '_');
        const contentDescriptionString = this.langService.getString(containsAllAttachments ? 'ZipReport.FullZipFileName_Substr' : 'ZipReport.PartZipFileName_Substr');

        const pdfFileName = this.langService.getString('ZipReport.PdfFileNameFormat_T', undefined, projectNameSanitized, projectDateTimeString);
        const zipFileName = this.langService.getString('ZipReport.ZipFileNameFormat_T', undefined, projectNameSanitized, projectDateTimeString, contentDescriptionString);

        let downloadFileName: string;
        if (fileType === 'application/pdf')
            downloadFileName = pdfFileName;
        else if (fileType === 'application/zip')
            downloadFileName = zipFileName;
        else
            throw new Error('Backend returned unexpected file type.');

        return downloadFileName;
    }

    /**
     * Sets the current project to null.
     */
    public closeProject(): void
    {
        this._currentProject = null;
    }

    private normalizeBackendProjectData(projectData: ProjectEntity | ProjectMetadataEntity)
    {
        projectData.CreatedUtc = this.globalizationService.dateTimeOffsetToDate(projectData.CreatedUtc as any, false);
        projectData.LastUpdatedUtc = this.globalizationService.dateTimeOffsetToDate(projectData.LastUpdatedUtc as any, false);
    }

    private singleProjectEndpoint(projectId: string): string
    {
        return `${this.projectCollectionEndpoint()}('${encodeURIComponent(projectId)}')`;
    }

    private projectCollectionEndpoint(): string
    {
        return `${this.apiRootUrl()}/Projects`;
    }

    private projectMetadataCollectionEndpoint(): string
    {
        return `${this.apiRootUrl()}/ProjectMetadata`;
    }

    private apiRootUrl(): string
    {
        return `${environment.backendRootUrl}api/v1`;
    }

    private async authorizationHeader(): Promise<string>
    {
        const token: string = await this.userService.getToken();
        if (!token)
            return null;

        return 'Bearer ' + token;
    }
}
