import { Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GenericDialogService } from 'src/core-lib/ej2/services/GenericDialogService';
import { ButtonPropsModel, DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { CellSelectEventArgs, ColumnSelectEventArgs, GridComponent, RowSelectEventArgs } from '@syncfusion/ej2-angular-grids';
import { Router } from '@angular/router';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { TextBoxComponent } from '@syncfusion/ej2-angular-inputs';
import { DatePipe } from '@angular/common';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { ProjectConfigurationItemComponent } from '../project-configuration-item/project-configuration-item.component';
import { CheckboxComponent } from 'src/app/shared/checkbox';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import { IProjectData, IStructureData, ITabData } from 'src/app/core/project-interfaces';
import { inputs } from '@syncfusion/ej2-angular-charts/src/chart/chart.component';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';
import { BaseDataDialogComponent } from '../base-data-dialog/base-data-dialog.component';
import { StructureDialogComponent } from '../structure-dialog/structure-dialog.component';
import { ConfigDteDialogComponent } from '../config-dte-dialog/config-dte-dialog.component';
import { ConfigDetailsDialogComponent } from '../config-details-dialog/config-details-dialog.component';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';
import { OtherBuilding } from 'src/app/core/BackendApi/otherbuilding.complex';
import { ResidentialBuilding } from 'src/app/core/BackendApi/residentialbuilding.complex';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { ContactData } from 'src/app/core/BackendApi/contactdata.complex';
import { Guid } from '@syncfusion/ej2-pdf-export';
import { HotWaterMath } from 'src/app/core/math/HotWaterMath';
import { ProjectMetadataEntity } from 'src/app/core/BackendApi/projectmetadataentity.entity';
import { SectionState } from 'src/app/core/Enums';
import { TechnicalAnalysis } from '@syncfusion/ej2-charts/src/chart/technical-indicators/indicator-base';
import { WebTrekkService } from 'src/app/core/WebTrekkService';
import { UserService } from 'src/app/core/UserService';
import { RuntimeService } from 'src/core-lib/ej2/services/RuntimeService';


@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
    providers: [provideContext("Overview")],
})
export class OverviewComponent implements OnInit
{
    private readonly MAX_PROJECT_COUNT: number = 100;

    @ViewChild('baseDataDialog') public BaseDataDialog: BaseDataDialogComponent;
    @ViewChild('structureDialog') public StructureDialog: StructureDialogComponent;
    @ViewChild("configDteDialog") public ConfigDteDialog: ConfigDteDialogComponent;
    @ViewChild("configDetailsDialog") public ConfigDetailsDialog: ConfigDetailsDialogComponent;
    @ViewChild("deleteProject") public DeleteProjectDialog: DialogComponent;
    @ViewChild("copyProject") public CopyProjectDialog: DialogComponent;
    @ViewChild("closeDialog") public CloseDialogDialog: DialogComponent;

    @ViewChild("structureDialog") public StructureOutDialog: StructureDialogComponent;

    @ViewChild('grid') public grid: GridComponent;
    @ViewChild('baseDataTab') public Tab: TabComponent;


    @ViewChild('TempProject') public TempProject: ProjectConfigurationItemComponent;
    @ViewChild('PipeProject') public PipeProject: ProjectConfigurationItemComponent;
    @ViewChild('EnergyProject') public EnergyProject: ProjectConfigurationItemComponent;

    selectedProjectId: string = null;
    selectedTabs: number[] = [];

    title = this.langService.getString('PanelHeadline_T');
    description = this.langService.getString('PanelDescription_D');
    dialogWidth = "40%"
    dialogHeight = "65em"
    dialogPosition: PositionDataModel = {X: 'center', Y: 'center'}
    target = "app-overview";

    baseDataHeader = this.langService.getString('BaseDataHeader_T');

    structureHeader = this.langService.getString('StructureHeader_T');

    selectedStructuresOther: OtherBuilding[];
    selectedStructureResidential: ResidentialBuilding;

    configDteHeader = this.langService.getString('ConfigDteHeader_T');
    isConfigDteSelected = false;

    configDetailsHeader = this.langService.getString('ConfigDetailsHeader_T');

    gridSortOptions = { columns: [{field: 'LastUpdatedUtc', direction: 'Descending'}]};
    gridToolbarOptions = ['Search'];
    searchSettings = { ignoreCase: true, operator: 'cotnains' }
    data: ProjectMetadataEntity[];

    newProject: ProjectEntity;
    project: ProjectEntity;

    currentAvvVersion: number = this.runtimeServive.getConfigValue("Auftragsverarbeitungsvertrag:Version");
    currentAvvLink: string = this.runtimeServive.getConfigValue("Auftragsverarbeitungsvertrag:Url");

    emptyTabsData: ContactData[] = //
    [
        { Name: null },
        { Name: null },
        { Name: null },
        { Name: null }
    ];

    isDteUfcChecked: boolean;
    isTemperatureChecked: boolean;
    isPipeChecked: boolean;
    isEnergyChecked: boolean;

    constructor(
        private readonly router: Router,
        private DataService: ProjectDataService,
        private langService: LangService,
        private tracker: WebTrekkService,
        private userService: UserService,
        private dialogService: GenericDialogService,
        private runtimeServive: RuntimeService
    ){

    }

    public ngOnInit(): void {
        this.DataService.listProjects().then(projects => this.data = projects);
        this.newProject = {
            ProjectId: Guid.getNewGuidString(),
            CreatedUtc: null,
            LastUpdatedUtc: null,
            ReportVersion: 1,
            SectionStates: {
                BuildingTypeBedarfsvolumenstromStates: [],
                BuildingTypeWarmwasserStates: [],
                Dte: SectionState.Empty,
                Energiespeicher: SectionState.Empty,
                Rohrauslegung: SectionState.Empty,
                Zirkulationsvolumenstrom: SectionState.Empty,
                Ufc: SectionState.Empty
            },
            Contacts: [
                { Name: null, Country: "DE" },
                { Name: null },
                { Name: null },
                { Name: null },
            ],
            ProjectValues: { KaltwasserTemperaturGrad: HotWaterMath.Pwc }
        };
        this.tracker.trackPage("projectlist", this.userService.userInfo.UserId, this.userService.isAuthenticated, this.newProject.ProjectId, this.newProject.Contacts ? {Zip: this.newProject.Contacts[0].Zip, Country: this.newProject.Contacts[0].Country, City: this.newProject.Contacts[0].City, Address: this.newProject.Contacts[0].Address} : null);
        this.checkAvvVersion();
    }

    dataBound(e){
    //     for (var i = 0; i < this.grid.getHeaderTable().querySelectorAll('.e-headercell').length; i++) {
    //              var margin = (<HTMLElement>this.grid.getHeaderTable().querySelectorAll('.e-headercell')[i]).offsetWidth - (42+((<HTMLElement>this.grid.getHeaderTable().querySelectorAll('.e-headertext')[i]).offsetWidth ) + 10);             // 42 - headerText padding & 10 is used for adjustment purpose

    //              (<HTMLElement>this.grid.getHeaderTable().querySelectorAll('.e-sortfilterdiv')[i]).style.marginRight = margin  + "px";
    //  }
    }

    gridCreated(){
        document.getElementById(this.grid.element.id + "_searchbar").addEventListener('keyup', () => {
                this.grid.search((event.target as HTMLInputElement).value)
        });
        var gridElement = this.grid.element;
        var span = document.createElement("span");
        span.className = "e-clear-icon";
        span.id = gridElement.id + "clear";
        span.onclick = this.cancelBtnClick.bind(this);
        gridElement.querySelector(".e-toolbar-item .e-input-group").appendChild(span);
    }

     public cancelBtnClick(args) {
    this.grid.searchSettings.key = "";
    (this.grid.element.querySelector(".e-input-group.e-search .e-input") as any).value = "";
  }

    resetDialogs(args){
        this.BaseDataDialog.resetDialog();
        this.ConfigDteDialog.resetDialog();
        this.ConfigDetailsDialog.resetDialog();
        this.StructureDialog.resetDialog();
    }

    removeElement(projectId: string){
        this.selectedProjectId = projectId;
        this.openDeleteProjectDialog();
    }

    copyElement(projectId: string){
        this.selectedProjectId = projectId;
        this.openCopyProjectDialog();
    }

    openBaseDialog = (): void => {
        if(this.data.length >= this.MAX_PROJECT_COUNT) {
            this.showMaxProjectsError();
            return;
        }

        // this.newProject = new IProjectData();
        this.resetVariables();
        this.BaseDataDialog.dialog.show();
        // this.addSelectedTab(0);
        this.tracker.trackAction("projectlist", "step1", this.userService.userInfo.UserId, this.userService.isAuthenticated, this.newProject.ProjectId, this.newProject.Contacts ? {Zip: this.newProject.Contacts[0].Zip, Country: this.newProject.Contacts[0].Country, City: this.newProject.Contacts[0].City, Address: this.newProject.Contacts[0].Address} : null);
    }

    closeBaseDialog = (): void => {
        this.BaseDataDialog.dialog.hide();
    }

    dialogBackStructureToBase = (): void => {
        this.StructureDialog.isClosedWithoutSave = false;
        this.StructureDialog.dialog.hide();
        this.BaseDataDialog.dialog.show();
        this.Tab.refresh();
        this.tracker.trackAction("projectlist", "step1", this.userService.userInfo.UserId, this.userService.isAuthenticated, this.newProject.ProjectId, this.newProject.Contacts ? {Zip: this.newProject.Contacts[0].Zip, Country: this.newProject.Contacts[0].Country, City: this.newProject.Contacts[0].City, Address: this.newProject.Contacts[0].Address} : null);
    }

    dialogBackConfigDteToStructure = (): void => {
        this.ConfigDteDialog.isClosedWithoutSave = false;
        this.ConfigDteDialog.dialog.hide();
        this.StructureDialog.dialog.show();
        this.tracker.trackAction("projectlist", "step2", this.userService.userInfo.UserId, this.userService.isAuthenticated, this.newProject.ProjectId, this.newProject.Contacts ? {Zip: this.newProject.Contacts[0].Zip, Country: this.newProject.Contacts[0].Country, City: this.newProject.Contacts[0].City, Address: this.newProject.Contacts[0].Address} : null);
    }

    dialogBackConfigDetailsToConfigDte = (): void => {
        this.ConfigDetailsDialog.isClosedWithoutSave = false;
        this.ConfigDetailsDialog.dialog.hide();
        this.ConfigDteDialog.dialog.show();
        this.tracker.trackAction("projectlist", "step3", this.userService.userInfo.UserId, this.userService.isAuthenticated, this.newProject.ProjectId, this.newProject.Contacts ? {Zip: this.newProject.Contacts[0].Zip, Country: this.newProject.Contacts[0].Country, City: this.newProject.Contacts[0].City, Address: this.newProject.Contacts[0].Address} : null);
    }



    dialogNextBaseToStructure(){
        this.BaseDataDialog.isClosedWithoutSave = false;
        this.BaseDataDialog.emitEditedProject();

        // Mit Forms-Funktionalität:
        if(this.BaseDataDialog.checkFormsValid()){
            this.BaseDataDialog.dialog.hide();
            this.StructureDialog.dialog.show();
            this.tracker.trackAction("projectlist", "step2", this.userService.userInfo.UserId, this.userService.isAuthenticated, this.newProject.ProjectId, this.newProject.Contacts ? {Zip: this.newProject.Contacts[0].Zip, Country: this.newProject.Contacts[0].Country, City: this.newProject.Contacts[0].City, Address: this.newProject.Contacts[0].Address} : null);

        }
        else{
            this.BaseDataDialog.errorDialog.show();
        }


    }



    dialogNextStructureToConfigDte = (): void => {
        this.StructureDialog.isClosedWithoutSave = false;
        this.StructureOutDialog.emitSelectedStructures();
        if(this.StructureDialog.selectedStructureResidential || (this.StructureDialog._selectedStructuresOther && this.StructureDialog.selectedStructuresOther.length > 0)){
            this.StructureDialog.dialog.hide();
            this.ConfigDteDialog.dialog.show();
            this.tracker.trackAction("projectlist", "step3", this.userService.userInfo.UserId, this.userService.isAuthenticated, this.newProject.ProjectId, this.newProject.Contacts ? {Zip: this.newProject.Contacts[0].Zip, Country: this.newProject.Contacts[0].Country, City: this.newProject.Contacts[0].City, Address: this.newProject.Contacts[0].Address} : null);
        }

    }



    dialogNextConfigDteToConfigDetails = (): void => {
        this.ConfigDteDialog.isClosedWithoutSave = false;

        this.ConfigDteDialog.dialog.hide();

        this.isTemperatureChecked = this.isDteUfcChecked; //Später an allen Stellen durch Überprüfung von DteUfc ersetzen

        this.ConfigDetailsDialog.dialog.show();
        this.tracker.trackAction("projectlist", "step4", this.userService.userInfo.UserId, this.userService.isAuthenticated, this.newProject.ProjectId, this.newProject.Contacts ? {Zip: this.newProject.Contacts[0].Zip, Country: this.newProject.Contacts[0].Country, City: this.newProject.Contacts[0].City, Address: this.newProject.Contacts[0].Address} : null);

    }

    navigateNext = (): void => {
        this.ConfigDetailsDialog.emitChecked();
        this.ConfigDteDialog.emitChange();

        if (this.selectedStructuresOther && this.selectedStructuresOther.length > 0) {
            this.newProject.OtherBuildings = this.selectedStructuresOther;

            this.selectedStructuresOther.forEach(o =>
                this.newProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.push(
                    { Key: o.BuildingType, Value: SectionState.Empty }
                )
            );

            this.selectedStructuresOther.forEach(o =>
                this.newProject.SectionStates.BuildingTypeWarmwasserStates.push(
                    { Key: o.BuildingType, Value: SectionState.Empty }
                )
            );
        }

        if(this.selectedStructureResidential){
            this.newProject.ResidentialBuilding = this.selectedStructureResidential;

            this.newProject.SectionStates.BuildingTypeBedarfsvolumenstromStates.push(
                { Key: this.selectedStructureResidential.BuildingType, Value: SectionState.Empty }
            );

            this.newProject.SectionStates.BuildingTypeWarmwasserStates.push(
                { Key: this.selectedStructureResidential.BuildingType, Value: SectionState.Empty }
            );
        }

        this.newProject.ConfigurationData = {
            Konfiguration_DTE_Temperaturabsenkung: this.isDteUfcChecked,
            Konfiguration_DTE_AuslegungRohrleitung: this.isPipeChecked,
            Konfiguration_Energiespeicher: this.isEnergyChecked
        }

        this.newProject.CreatedUtc = new Date();
        this.newProject.LastUpdatedUtc = new Date();
        var projectAmount = 0;
        if(this.newProject.ResidentialBuilding){
            projectAmount = 1;
        }
        if(this.newProject.OtherBuildings){
            projectAmount = projectAmount + this.newProject.OtherBuildings.count();
        }
        this.tracker.trackActionCustom("projectlist", "stepsFinished",
        {
            userId: this.userService.userInfo.UserId,
            intern: this.userService.isAuthenticated,
            projectId: this.newProject.ProjectId,
            projectSite: this.newProject.Contacts ? {Zip: this.newProject.Contacts[0].Zip, Country: this.newProject.Contacts[0].Country, City: this.newProject.Contacts[0].City, Address: this.newProject.Contacts[0].Address} : null,
            buildingAmount: projectAmount,
            selectedDte: this.newProject.ConfigurationData.Konfiguration_DTE_Temperaturabsenkung ? "DTE Standardbetrieb" : "DTE Temperaturabsenkung",
            energySelected: this.newProject.ConfigurationData.Konfiguration_Energiespeicher ? "True" : "False",
            pipeSelected: this.newProject.ConfigurationData.Konfiguration_DTE_AuslegungRohrleitung ? "True" : "False"
        });
        this.DataService.addAndSelectNewProject(this.newProject)
        .then(project => {
            this.ConfigDetailsDialog.dialog.hide();
            this.router.navigate([`/project-view/${project.ProjectId}`]);
        }).catch(e => {
            console.error(e);
            if(e.responseStatus === 403 && e.odataErrorMsg?.code === "MaxUserProjectCountException") {
                this.showMaxProjectsError();
            } else {
                this.dialogService.showErrorDetailsDialog(
                    JSON.stringify(e, undefined, 2),
                    "max(40vw, 35em)",
                    "app-overview"
                );
            }
        });
    }

    private showMaxProjectsError(): void {
        this.dialogService.showSimpleDialog(
            this.langService.getString("MaxProjectsError.Title_T"),
            this.langService.getString("MaxProjectsError.Description_T"),
            [ this.langService.getString("MaxProjectsError.Button_T") ],
            "max(40vw, 35em)",
            "app-overview"
        );
    }

    setSelectedProjectValues(args){ //ProjectName = undefined???
        var newProjectData = args;

          this.newProject.ProjectName = newProjectData.informationFields.ProjectName;
          this.newProject.ProjectBuildSection = newProjectData.informationFields.ConstructionPhase;
          this.newProject.ProjectNumber = newProjectData.informationFields.ProjectId;
          this.newProject.ProjectDescription = newProjectData.informationFields.Description;
          this.newProject.AvvVersion = this.currentAvvVersion;

            for (let j = 0; j < 4; j++) {
                this.newProject.Contacts[j].Name = newProjectData.contactFields[j]["Name_" + j];
                this.newProject.Contacts[j].Address = newProjectData.contactFields[j]["Address_" + j];
                this.newProject.Contacts[j].Country = newProjectData.contactFields[j]["Country_" + j];
                this.newProject.Contacts[j].Zip = newProjectData.contactFields[j]["Zip_" + j];
                this.newProject.Contacts[j].City = newProjectData.contactFields[j]["City_" + j];
                this.newProject.Contacts[j].Phone = newProjectData.contactFields[j]["Phone_" + j];
                this.newProject.Contacts[j].Email = newProjectData.contactFields[j]["Email_" + j];
            }



    }

    openDeleteProjectDialog = (): void => {
        this.DeleteProjectDialog.show();
      }

      closeDeleteProjectDialog = (): void => {
        this.DeleteProjectDialog.hide();
        this.grid.hideScroll();

      }


      openCopyProjectDialog = (): void => {
        this.CopyProjectDialog.show();
      }

      closeCopyProjectDialog = (): void => {
        this.CopyProjectDialog.hide();
      }

    confirmDeleteObject = (): void => {
        this.tracker.trackAction("projectlist", "deleteProject", this.userService.userInfo.UserId, this.userService.isAuthenticated, this.selectedProjectId, this.newProject.Contacts ? {Zip: this.newProject.Contacts[0].Zip, Country: this.newProject.Contacts[0].Country, City: this.newProject.Contacts[0].City, Address: this.newProject.Contacts[0].Address} : null);
        var toDelete = this.data.find(p => p.ProjectId == this.selectedProjectId);
        var index = this.data.indexOf(toDelete);
        this.data.splice(index, 1);
        this.grid.refresh();



        this.DataService.deleteProject(this.selectedProjectId)
        .then(_ => this.closeDeleteProjectDialog());
    }

    confirmCopyObject(){
        this.tracker.trackAction("projectlist", "copyProject", this.userService.userInfo.UserId, this.userService.isAuthenticated, this.selectedProjectId, this.newProject.Contacts ? {Zip: this.newProject.Contacts[0].Zip, Country: this.newProject.Contacts[0].Country, City: this.newProject.Contacts[0].City, Address: this.newProject.Contacts[0].Address} : null);

        this.DataService.cloneProject(this.selectedProjectId)
        .then(project => {
            this.data.push(project)
            this.closeCopyProjectDialog();
            this.grid.refresh();
        });
    }

    async rowSelected(data: ProjectMetadataEntity){
        this.DataService.selectProject(data.ProjectId)
        .then(_ => this.router.navigate([`/project-view/${data.ProjectId}`]));
    }

    confirmCloseDialog(){// TODO nicht so elegant gelöst
        this.BaseDataDialog.canBeClosed = true;
        this.StructureDialog.canBeClosed = true;
        this.ConfigDteDialog.canBeClosed = true;
        this.ConfigDetailsDialog.canBeClosed = true;

        this.CloseDialogDialog.hide();
        this.BaseDataDialog.dialog.hide();
        this.StructureDialog.dialog.hide();
        this.ConfigDteDialog.dialog.hide();
        this.ConfigDetailsDialog.dialog.hide();

        this.BaseDataDialog.canBeClosed = false;
        this.StructureDialog.canBeClosed = false;
        this.ConfigDteDialog.canBeClosed = false;
        this.ConfigDetailsDialog.canBeClosed = false;
    }

    cancelCloseDialog(){
        this.CloseDialogDialog.hide();
    }

    baseDataButtons = [
        {
            'click': this.closeBaseDialog.bind(this),
            buttonModel:{
                content: this.langService.getString('BaseDataAbbrechenBtn_T'),
                isPrimary: false,
            }
        },
        {
        'click': this.dialogNextBaseToStructure.bind(this),
        buttonModel:{
            content: this.langService.getString('BaseDataWeiterBtn_T') + '&nbsp;<span class="icon-angle-right"></span> ',
            isPrimary: false,
            cssClass: 'e-primary'
        }
        }];

    structureButtons = [
        {
            'click': this.dialogBackStructureToBase.bind(this),
            buttonModel:{
                content: '<span class="icon-angle-left"></span> &nbsp;' + this.langService.getString('StructureZurueckBtn_T'), //Icon
                isPrimary: false,

            }
        },
        {
            'click': this.dialogNextStructureToConfigDte.bind(this),
            buttonModel:{
                content: this.langService.getString('StructureWeiterBtn_T') + '&nbsp; <span class="icon-angle-right"></span>',
                isPrimary: false,
                cssClass: 'e-primary'
            }
        },
    ];

    configDteButtons = [
        {
            'click': this.dialogBackConfigDteToStructure.bind(this),
            buttonModel:{
                content: '<span class="icon-angle-left"></span> &nbsp;' + this.langService.getString('ConfigDteZurueckBtn_T'), //Icon
                isPrimary: false
            }
        },
        {
        'click': this.dialogNextConfigDteToConfigDetails.bind(this),
        buttonModel:{
            content: this.langService.getString('ConfigDteWeiterBtn_T') + ' &nbsp; <span class="icon-angle-right"></span>',
            isPrimary: false,
            cssClass: 'e-primary'
        }
    } ];

    configDetailsButtons = [
        {
            'click': this.dialogBackConfigDetailsToConfigDte.bind(this),
            buttonModel:{
                content: '<span class="icon-angle-left"></span> &nbsp;' + this.langService.getString('ConfigDetailsZurueckBtn_T'), //Icon
                isPrimary: false
            }
        },
        {
        'click': this.navigateNext.bind(this),
        buttonModel:{
            content: this.langService.getString('ConfigDetailsProjektAnlegenBtn_T') + '&nbsp; <span class="icon-angle-right"></span>',
            isPrimary: false,
            cssClass: 'e-primary'
        }
    } ];


    deleteProjectButtons = [ {
        'click': this.closeDeleteProjectDialog.bind(this),
        buttonModel:{
            content: this.langService.getString('DeleteProjectAbbrechenBtn_T'),
            isPrimary: false
        }
      },
      {
        'click': this.confirmDeleteObject.bind(this),
        buttonModel:{
          content: this.langService.getString('DeleteProjectLoeschenBtn_T'),
          isPrimary: false,
            cssClass: 'e-primary'
        }
      }];

      copyProjectButtons = [ {
        'click': this.closeCopyProjectDialog.bind(this),
        buttonModel:{
            content: this.langService.getString('CopyProjectAbbrechenBtn_T'),
            isPrimary: false
        }
      },
      {
        'click': this.confirmCopyObject.bind(this),
        buttonModel:{
            content: this.langService.getString('CopyProjectKopierenBtn_T'),
            isPrimary: false,
            cssClass: 'e-primary'
        }
      }];

      closeDialogButtons: ButtonPropsModel[] = [
          {
              'click': this.cancelCloseDialog.bind(this),
              buttonModel: {
                content: this.langService.getString('CloseDialogAbbrechenBtn_T'),
              }
          },
          {
            'click': this.confirmCloseDialog.bind(this),
            buttonModel: {
                content: this.langService.getString('CloseDialogVerwerfenBtn_T'),
                isPrimary: false,
                cssClass: 'e-primary'
            }
          }
      ]


      checkDialogsPristine(){
        if(!this.BaseDataDialog.checkFormsPristine())
        {
            this.CloseDialogDialog.show();
        }
      }

    resetVariables(){
        this.isDteUfcChecked = false;

        this.isEnergyChecked = true;
        this.isPipeChecked = true;
    }

    async checkAvvVersion(){
        const wrongVersionProjects: string[] = [];
        const projectsIDs: string[] = [];
        let projects = await this.DataService.listProjects();
        projects.forEach(project => {
            if(project.AvvVersion !== this.currentAvvVersion){
                wrongVersionProjects.push(project.ProjectName);
                projectsIDs.push(project.ProjectId);
            }
        })
        if(wrongVersionProjects.length > 0){
            let acceptedAvv = await this.dialogService.showSimpleYesNoDialog(
                this.langService.getString("AvvVersionDialog.Title_T"),
                this.langService.getString("AvvVersionDialog.Description1_T")
                + `<a href=${this.currentAvvLink} target="_blank">${this.langService.getString("AvvVersionDialog.Description2_T")}</a>`
                + this.langService.getString("AvvVersionDialog.Description3_T")
                + wrongVersionProjects.join(", "),
                this.langService.getString("AvvVersionDialog.ButtonAccept_T") ,
                this.langService.getString("AvvVersionDialog.ButtonDecline_T"),
                "max(40vw, 35em)",
                "app-overview"
            );
            if(acceptedAvv){
                projectsIDs.forEach(async projectID => {
                    let project = await this.DataService.selectProject(projectID)
                    project.AvvVersion = this.currentAvvVersion;
                    await this.DataService.saveCurrentProject();
                })
            }
        }
    }
}
