import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { ValidatorFn, Validators } from '@angular/forms';
import { ButtonComponent } from '@syncfusion/ej2-angular-buttons';
import { TabComponent } from '@syncfusion/ej2-angular-navigations';
import { DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { AggregatorDataService } from 'src/app/core/AggregatorDataService';
import { ProjectEntity } from 'src/app/core/BackendApi/projectentity.entity';
import { ProjectDataService } from 'src/app/core/project-data.service';
import { IProjectData, ITabData } from 'src/app/core/project-interfaces';
import { ProjectService } from 'src/app/core/ProjectService';
import { PspFormField } from 'src/core-lib/angular/components/forms/PspFormField';
import { PspFormFieldGroup } from 'src/core-lib/angular/components/forms/PspFormFieldGroup';
import { provideContext } from 'src/core-lib/angular/context-util';
import { LangService } from 'src/core-lib/ej2/services/LangService';
import { RuntimeService } from 'src/core-lib/ej2/services/RuntimeService';


@Component({
  selector: 'app-base-data-dialog',
  templateUrl: './base-data-dialog.component.html',
  styleUrls: ['./base-data-dialog.component.scss'],
  providers: [provideContext("BaseDataDialog")],
})
export class BaseDataDialogComponent implements OnInit {

    @ViewChild('dialog') public dialog: DialogComponent;
    @ViewChild('errorDialog') public errorDialog: DialogComponent;
    @ViewChild('tabs') public tabs: TabComponent;

    @Input() target: string;
    @Input() buttons: ButtonComponent[];
    @Input() headerText: string;

    tabHeaders: string[] = [
        this.langService.getString('TabHeaders.Standort_T'),
        this.langService.getString('TabHeaders.Bauherr_T'),
        this.langService.getString('TabHeaders.Planer_T'),
        this.langService.getString('TabHeaders.Firma_T')
    ]

    inputPlaceholder: string = "Bitte eingeben..."

    dialogWidth: string = "55%";
    dialogHeight: string = "90vh";
    dialogPosition: PositionDataModel = {X: 'center', Y: 'center'}

    errorDialogWidth = "40%"
    errorDialogHeader = this.langService.getString('ErrorDialog.Header_T');

    public countryTooltipText: string;
    public projectNameTooltipText: string;
    public nameTooltipText: string;
    public projectIdTooltipText: string;
    public descriptionTooltipText: string;
    public acceptedAvv: boolean = false;
    public avvUrl: string = this.runtimeInfoService.getConfigValue('Auftragsverarbeitungsvertrag:Url')
    public acceptAvv: string = this.langService.getString('AcceptAvv_T');

    selectedTabs: number[] = [];
    public tabsAnimation: object = { previous: { effect: '', duration: 0, easing: ''}, next: { effect: '', duration: 0, easing: '' } };

    @Input()
    public editingProject: ProjectEntity;
    @Output()
    public projectEdited = new EventEmitter<any>();

    @Output() closedCompletely = new EventEmitter<any>()
    isClosedWithoutSave: boolean = true;

    canBeClosed: boolean = false;

    @Output() dialogPristine = new EventEmitter<any>()

    public projectInformationFields: PspFormFieldGroup;
    public projectContactInformationFields: PspFormFieldGroup[];


    constructor(private langService: LangService,
        private aggregatorDataService: AggregatorDataService,
        private runtimeInfoService: RuntimeService,
        ) { }


    ngOnInit(): void {
        this.countryTooltipText = this.getHelpTextContent("Projektstammdaten.Land");
        this.projectNameTooltipText = this.getHelpTextContent("Projektstammdaten.Projektname");
        this.nameTooltipText = this.getHelpTextContent("Projektstammdaten.Bezeichnung");
        this.projectIdTooltipText = this.getHelpTextContent("Projektstammdaten.Projektnummer");
        this.descriptionTooltipText = this.getHelpTextContent("Projektstammdaten.Bemerkung");

        this.initFields();
    }

    emitEditedProject(){
        var informationFields = this.projectInformationFields.group.value;
        var contactFields = [];

        for (let i = 0; i < this.projectContactInformationFields.length; i++) {
            const element = this.projectContactInformationFields[i].group.value;
            contactFields.push(element);
        }

        var newProjectValues = {
            informationFields: informationFields,
            contactFields: contactFields
        }

        this.projectEdited.emit(newProjectValues);
    }

    private initFields(): void
    {
        this.projectContactInformationFields = [
            new PspFormFieldGroup(),
            new PspFormFieldGroup(),
            new PspFormFieldGroup(),
            new PspFormFieldGroup(),
        ];

        this.projectInformationFields = new PspFormFieldGroup(
            [
                new PspFormField(
                {
                    fieldName: 'ProjectName',
                    displayName: this.langService.getString('ProjectName_T'),
                    formState:{
                        value: this.editingProject.ProjectName,
                        disabled: false
                    },
                    helpMessageHtml: this.projectNameTooltipText,
                    displayAsRequired: true,
                    validatorOrOpts: [Validators.required, Validators.pattern('[\\w\\d\-\.()/\\!"\'+?*~#_°^=%$§€@äöüÄÖÜß:;,<>& ]+'), Validators.minLength(1), Validators.maxLength(60)]
                }),
                new PspFormField(
                    {
                        fieldName: 'ConstructionPhase',
                        displayName: this.langService.getString('ConstructionPhase_T'),
                        formState:{
                            value: this.editingProject.ProjectBuildSection,
                            disabled: false
                        },
                        displayAsRequired: false,
                        validatorOrOpts: [Validators.pattern('[\\w\\d\-\.()/\\!"\'+?*~#_°^=%$§€@äöüÄÖÜß:;,<>& ]*'), Validators.minLength(0), Validators.maxLength(60)],
                    }),
                new PspFormField(
                    {
                        fieldName: 'ProjectId',
                        displayName: this.langService.getString('ProjectId_T'),
                        formState:{
                            value: this.editingProject.ProjectNumber,
                            disabled: false
                        },
                        helpMessageHtml: this.projectIdTooltipText,
                        displayAsRequired: false,
                        validatorOrOpts: [Validators.pattern('[\\w\\d\-\.()/\\!"\'+?*~#_°^=%$§€@äöüÄÖÜß:;,<>& ]+'), Validators.minLength(1), Validators.maxLength(30)]
                    }),
                new PspFormField(
                    {
                        fieldName: 'Description',
                        displayName: this.langService.getString('Description_T'),
                        formState:{
                            value: this.editingProject.ProjectDescription,
                            disabled: false
                        },
                        helpMessageHtml: this.descriptionTooltipText,
                        displayAsRequired: false,
                        validatorOrOpts: [Validators.pattern('[\\w\\d\\r\\n\-\.()/\\!"\'+?*~#_°^=%$§€@äöüÄÖÜß:;,<>& ]*'), Validators.minLength(0), Validators.maxLength(250)]
                    }),
                new PspFormField({
                    fieldName: 'AvvVersion',
                    displayName: this.langService.getString('AvvVersion_T'),
                    formState:{
                        value: this.runtimeInfoService.getConfigValue('Auftragsverarbeitungsvertrag:Version'),
                        disabled: false
                    },
                    displayAsRequired: true,
                    validatorOrOpts: [Validators.required]
                })
        ])

        this.addTabsFields("Name", this.langService.getString('TabFields.Name_T'), true, "Name",[Validators.pattern('[\\w\\d\-\.()/\\!"\'+?*~#_°^=%$§€@äöüÄÖÜß:;,<>& ]*'), Validators.minLength(0), Validators.maxLength(40)], [Validators.required]);
        this.addTabsFields("Address", this.langService.getString('TabFields.Address_T'), false, "Address", [Validators.pattern('[\\w\\d\-\.()/\\!"\'+?*~#_°^=%$§€@äöüÄÖÜß:;,<>& ]*'), Validators.minLength(0), Validators.maxLength(45)]);
        this.addTabsFields("Country", this.langService.getString('TabFields.Country_T'), false, "Country",[Validators.pattern('[\\w\\d\-\.()/\\!"\'+?*~#_°^=%$§€@äöüÄÖÜß:;,<>& ]*'), Validators.minLength(0), Validators.maxLength(20)]);
        this.addTabsFields("Zip", this.langService.getString('TabFields.Zip_T'), false, "Zip", [Validators.pattern('[\\w\\d\-\.()/\\!"\'+?*~#_°^=%$§€@äöüÄÖÜß:;,<>& ]*'), Validators.minLength(0), Validators.maxLength(20)]);
        this.addTabsFields("City", this.langService.getString('TabFields.City_T'), false, "City", [Validators.pattern('[\\w\\d\-\.()/\\!"\'+?*~#_°^=%$§€@äöüÄÖÜß:;,<>& ]*'), Validators.minLength(0), Validators.maxLength(40)]);
        this.addTabsFields("Phone", this.langService.getString('TabFields.Phone_T'), false, "Phone", [Validators.pattern('[\\w\\d\-,;\.()/\\"\'#\+& ]*'), Validators.minLength(0), Validators.maxLength(40)]);
        this.addTabsFields("Email", this.langService.getString('TabFields.EMail_T'), false, "Email", [Validators.pattern('[\\w\\d\-,;\._@%äöüÄÖÜß]*'), Validators.minLength(0), Validators.maxLength(60)]);

    }

    addTabsFields(fieldName: string, displayName: string, required: boolean, property: string,
        validators?: ValidatorFn[], validatorsFirst?: ValidatorFn[]
        ){
        for (let i = 0; i < 4; i++) {

            var field = new PspFormField(
                {
                    fieldName: fieldName + "_" + i.toString(),
                    displayName: displayName,
                    formState: {
                        value: this.editingProject.Contacts[i][property],
                        disabled: false
                    },
                    displayAsRequired: (fieldName == "Name" && i === 0 ? true : false),
                    validatorOrOpts: (fieldName == "Name" && i === 0 ? validatorsFirst : validators),
                    helpMessageHtml: i !== 0 ? null : (fieldName == "Country" ? this.countryTooltipText : (fieldName == "Name" ? this.nameTooltipText : null))
                }
            );
            this.projectContactInformationFields[i].addField(field);
        }
    }


    onCloseDialog(){
        if(this.isClosedWithoutSave){
            this.closedCompletely.emit();
            this.resetDialog();
        }
    }

    onBeforeClose(event){
        if(this.target == 'app-overview'){
            if(this.isClosedWithoutSave){
                if(!this.checkFormsPristine() && !this.canBeClosed){
                    event.cancel = true;
                    this.dialogPristine.emit();
                }
            }
        }
        else{
            if (this.isClosedWithoutSave) {
                if(!this.checkFormsPristine() && !this.canBeClosed){
                    event.cancel = true;
                    this.dialogPristine.emit();
                }
            }
        }
    }

    public resetDialog(){
            //OverviewComponent
            if(this.target === "app-overview"){
                this.projectInformationFields.group.reset();

                this.projectContactInformationFields[0].group.reset({Country_0: "DE"});

                for (let i = 1; i < 4; i++) {
                    this.projectContactInformationFields[i].group.reset();
                }
            }

            //ProjectViewComponent
            else{
                this.projectInformationFields.group.reset({
                    ProjectName: this.editingProject.ProjectName,
                    ConstructionPhase: this.editingProject.ProjectBuildSection,
                    ProjectId: this.editingProject.ProjectNumber,
                    Description: this.editingProject.ProjectDescription
                })


                for (let i = 0; i < this.editingProject.Contacts.length; i++) {
                    this.projectContactInformationFields[i].group.reset({
                        ["Name_" + i]: this.editingProject.Contacts[i].Name,
                        ["Address_" + i]: this.editingProject.Contacts[i].Address,
                        ["Country_" + i]: this.editingProject.Contacts[i].Country,
                        ["Zip_" + i]: this.editingProject.Contacts[i].Zip,
                        ["City_" + i]: this.editingProject.Contacts[i].City,
                        ["Phone_" + i]: this.editingProject.Contacts[i].Phone,
                        ["Email_" + i]: this.editingProject.Contacts[i].Email,
                    })
                }

            }
    }


    tabSelected(args){
        this.addSelectedTab(args.selectedIndex);
    }

    addSelectedTab(index: number){
        if(!(this.selectedTabs.indexOf(index) > -1)){
            this.selectedTabs.push(index);
        }
    }



    onOpenDialog(){
        this.isClosedWithoutSave = true
    }

    onOverlayClick(){
        this.dialog.hide();
    }

    onOverlayClickError(){
        this.errorDialog.hide();
    }

    onAcceptedAvvChange($event: boolean) {
        this.acceptedAvv = $event;
    }

    checkFormsPristine(): boolean{
        if (this.projectInformationFields.group.pristine &&
            this.projectContactInformationFields[0].group.pristine &&
            this.projectContactInformationFields[1].group.pristine &&
            this.projectContactInformationFields[2].group.pristine &&
            this.projectContactInformationFields[3].group.pristine)
        {
            return true

        } else {
            return false;
        }
    }

    checkFormsValid(): boolean{
        if (this.projectInformationFields.group.valid &&
            this.projectContactInformationFields[0].group.valid &&
            this.projectContactInformationFields[1].group.valid &&
            this.projectContactInformationFields[2].group.valid &&
            this.projectContactInformationFields[3].group.valid)
            {
                return true

        } else {
            return false;
        }
    }

    private getHelpTextContent(key: string): string {
        return this.aggregatorDataService.aggregatorData.Tables.TooltipRows
            .find(x => x.TooltipId == key).TooltipContentHtmlLoc;
    }
}
